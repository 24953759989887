"use client";

import { useQuery } from "@tanstack/react-query";
import { isToday } from "date-fns";
import { useSession } from "next-auth/react";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { trackEvent } from "./analytics-wrapper";
import { BaseModal } from "./base-modal";
import { FramePack } from "./frame-pack";
import { GradientBorder } from "./gradient-border";
import { HeaderLogo } from "./header";
import {
  createSubscriptionCheckout,
  fetchPlans,
  PlanInfo,
} from "@/app/(signed-out)/pricing/actions";
import { formatCurrency } from "@/helpers/currency";

export type UpsellModal =
  | "unlockVideoToVideo"
  | "unlockImageToVideo"
  | "unlockTextToVideo"
  | "unlockFaceSwap"
  | "outOfFrames"
  | "buyFramePack"
  | "unlockHigherResolution";

interface ModalOptions {
  /**
   * overwrite the daily requirement and show the modal immediately
   */
  forceShow?: boolean;
}

interface UpsellModalContext {
  open: (type: UpsellModal, options?: ModalOptions) => void;
  isOpen: boolean;
  type: UpsellModal | null;
}

const UpsellModalContext = createContext<UpsellModalContext>({
  open: () => {
    throw new Error("useUpsellModal must be used within a UpsellModalProvider");
  },
  isOpen: false,
  type: null,
});

export function useUpsellModal() {
  const context = useContext(UpsellModalContext);
  if (context === undefined) {
    throw new Error("useUpsellModal must be used within a UpsellModalProvider");
  }

  return context;
}

const upsellModalContent: Record<UpsellModal, { title: string; description: string }> = {
  unlockVideoToVideo: {
    title: "Unlock Video-to-Video with subscription or frame pack",
    description: "Plus, enjoy higher resolution, no watermark, and additional frames",
  },
  unlockTextToVideo: {
    title: "Unlock Text-to-Video with subscription",
    description: "Plus, enjoy higher resolution, no watermark, and additional frames",
  },
  unlockImageToVideo: {
    title: "Unlock Image-to-Video with subscription",
    description: "Plus, enjoy higher resolution, no watermark, and additional frames",
  },
  unlockFaceSwap: {
    title: "Unlock Face Swap with subscription",
    description: "Plus, enjoy higher resolution, no watermark, and additional frames",
  },
  outOfFrames: {
    title: "Get more frames with subscription",
    description: "Plus, enjoy higher resolution, no watermark, and video-to-video",
  },
  buyFramePack: {
    title: "Buy a frame pack",
    description: "Keep exploring with more frames",
  },
  unlockHigherResolution: {
    title: "Get higher resolution with subscription",
    description: "Plus, enjoy more frames, no watermark, and video-to-video",
  },
};

const upsellModalKey = "upsellModalLastTriggerTime";

export function UpsellModalProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);

  const { data: session } = useSession();
  const [type, setType] = useState<UpsellModal | null>(null);
  const [plan, setPlan] = useState<PlanInfo>();

  const handleClose = () => {
    setIsOpen(false);
    if (type) {
      trackEvent({ eventName: `upsellModal-${type}`, action: "dismiss" });
    }
  };

  const plansQuery = useQuery({
    queryKey: ["upsell-modal-plans"],
    queryFn: async () => {
      const plans = await fetchPlans();

      setPlan(plans.subscriptions.find((p) => p.mostPopular));

      return plans;
    },
    enabled: isOpen,
  });

  const framePackPriceId = plansQuery.data?.framePack?.price?.id;

  const open = (type: UpsellModal, options?: ModalOptions) => {
    if (typeof window === "undefined") {
      return;
    }

    setType(type);

    let canTrigger = true;

    const lastTriggerTime = localStorage.getItem(upsellModalKey);

    if (lastTriggerTime) {
      const triggerTime = parseInt(lastTriggerTime, 10);

      canTrigger = !isToday(triggerTime);
    }

    if (!canTrigger && !options?.forceShow) {
      return;
    }

    trackEvent({ eventName: `upsellModal-${type}`, action: "view" });

    localStorage.setItem(upsellModalKey, Date.now().toString());
    setIsOpen(true);
  };

  const content = upsellModalContent[type ?? "unlockVideoToVideo"];

  return (
    <UpsellModalContext.Provider value={{ open, isOpen, type }}>
      {children}
      <BaseModal
        isOpen={isOpen}
        handleClose={handleClose}
        title={
          <div className="flex gap-2">
            <HeaderLogo /> <span className="mb-1">Magic Hour</span>
          </div>
        }
      >
        <div className="mt-4 flex flex-col gap-4">
          <div className="text-2xl">{content.title}</div>

          <div className="text-lg">
            {content.description}
            {type === "buyFramePack" && session?.user.subscriptionTier === "free"
              ? ", plus no watermark"
              : ""}
          </div>

          {type === "buyFramePack" && framePackPriceId ? (
            <FramePack priceId={framePackPriceId} />
          ) : (
            <>
              <div className="flex min-h-20 flex-col gap-2">
                {plansQuery.isLoading ? (
                  <ScaleLoader color="lightgray" className="mx-auto" />
                ) : (
                  plansQuery.data?.subscriptions?.map((plan) => {
                    if (plan.id.includes("free")) return null;
                    if (plan.name.includes("Business")) return null;

                    const label =
                      type === "unlockHigherResolution"
                        ? plan.features.find((feature) =>
                            feature.toLowerCase().includes("resolution")
                          )
                        : `${plan.prices.monthly.frames.toLocaleString()} monthly frames`;

                    return (
                      <div key={plan.id} className="group flex items-center gap-2">
                        <input
                          id={plan.id}
                          defaultChecked={plan.name.toLocaleLowerCase().includes("creator")}
                          type="radio"
                          name="price_id"
                          value={plan.prices.monthly.id}
                          onClick={() => setPlan(plan)}
                          className="h-4 w-4 border-slate-600 bg-slate-700 text-purple-600 focus:ring-2 focus:ring-purple-600 group-hover:cursor-pointer"
                        />
                        <label htmlFor={plan.id} className="group-hover:cursor-pointer">
                          Get {label} for{" "}
                          {formatCurrency(plan.prices.monthly.amount, plan.prices.monthly.currency)}
                          /month
                        </label>
                      </div>
                    );
                  })
                )}
              </div>

              <div className="flex justify-start gap-6">
                <div className="w-fit overflow-hidden rounded-full">
                  <button
                    className="focus:outline-none disabled:cursor-not-allowed"
                    disabled={plansQuery.isLoading}
                    onClick={() => {
                      if (type) {
                        trackEvent({ eventName: `upsellModal-${type}`, action: "click" });
                      }

                      if (!plan) {
                        return;
                      }

                      const price = plan.prices.monthly;

                      createSubscriptionCheckout({
                        priceId: price.id,
                        email: session?.user?.email || undefined,
                        customerId: session?.user?.stripeCustomerId || undefined,
                        coupon: undefined,
                        lookupKey: price.lookupKey,
                      });
                    }}
                  >
                    <GradientBorder borderSize={2}>
                      <div className="rounded-full bg-slate-800 p-1 px-4 py-1 hover:bg-slate-700">
                        Get Magic Hour {plan?.name ?? "Creator"}
                      </div>
                    </GradientBorder>
                  </button>
                </div>

                <button
                  className="text-sm text-slate-500 hover:text-slate-300 focus:outline-none disabled:cursor-not-allowed"
                  onClick={handleClose}
                  disabled={plansQuery.isLoading}
                  type="button"
                >
                  Not Now
                </button>
              </div>
            </>
          )}

          <p className="text-left text-xs text-slate-500">
            See{" "}
            <a href="/pricing#most-popular" className="text-teal-500 focus:outline-none">
              Pricing
            </a>{" "}
            for more details
          </p>
        </div>
      </BaseModal>
    </UpsellModalContext.Provider>
  );
}
