"use client";

import { ReactNode, useEffect } from "react";
import { webAssetsCdnBaseUrl } from "./constants";
import { classNames } from "@/helpers/tailwind-class";

interface HeaderProps {
  left: ReactNode;
  middle: ReactNode;
  right: ReactNode;
  staticStyle?: boolean;
}

export function Header({ left, middle, right, staticStyle = false }: HeaderProps) {
  useEffect(() => {
    if (staticStyle) {
      return;
    }

    if (window.scrollY > 0) {
      const header = document.getElementById("header");
      if (header) {
        header.classList.add("bg-slate-950");
      }
    }

    const scrollHandler = () => {
      const header = document.getElementById("header");
      if (header) {
        if (window.scrollY > 0) {
          header.classList.add("bg-slate-950");
        } else {
          header.classList.remove("bg-slate-950");
        }
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [staticStyle]);

  return (
    <>
      <div
        id="header"
        className={classNames(
          "fixed inset-0 z-30 mx-auto grid h-[68px] w-screen grid-cols-12 justify-between px-4 py-4 transition duration-300 sm:px-6",
          staticStyle ? "bg-slate-900" : ""
        )}
      >
        <div className="col-span-3 flex justify-start">{left}</div>

        <div className="col-span-6 flex items-center justify-center lg:pl-10">{middle}</div>

        <div className="col-span-3 flex items-center justify-end">{right}</div>
      </div>
    </>
  );
}

export function HeaderLogoWithName() {
  return (
    <a className="flex flex-shrink-0 items-center" href="/">
      <HeaderLogo />
      <h3 className="mx-2 pb-[2px] text-sm font-bold text-neutral-50 sm:text-xl">Magic Hour</h3>
    </a>
  );
}

export function HeaderLogo() {
  return (
    <img height={34} width={34} src={`${webAssetsCdnBaseUrl}/logo.svg`} alt="Magic Hour Logo" />
  );
}
